import { defineComponent, ref, watch, reactive } from '@vue/composition-api';
import Breadcrumbs from '@/components/Breadcrumbs.vue';
import { useGetCollectionNameQuery } from '@/generated-types/graphql.types';
import { OrganisationRoutes } from '@/Organisation/organisation.const';
const steps = [
    {
        to: '',
        title: 'geo_pages.breadcrumbs.home',
        disabled: false,
        isStatic: true
    },
    {
        to: 'search',
        title: 'common.breadcrumbs.search',
        disabled: true,
        isStatic: true
    }
];
export default defineComponent({
    components: {
        Breadcrumbs
    },
    setup(_, context) {
        const { root } = context;
        let links = ref(steps);
        const queryVariables = reactive({
            slug: ''
        });
        const queryOptions = reactive({
            enabled: false,
            fetchPolicy: 'no-cache'
        });
        const { refetch, onResult } = useGetCollectionNameQuery(queryVariables, queryOptions);
        watch(() => root.$route, () => {
            const collectionSlug = root.$route.query?.organisation;
            if (collectionSlug) {
                queryVariables.slug = collectionSlug;
                queryOptions.enabled = true;
                refetch(queryVariables);
                onResult((result) => {
                    const collectionName = result?.data?.get_collection.collection_name;
                    if (collectionName && links.value.length === 2) {
                        const orgListItem = {
                            to: `${OrganisationRoutes.ORGANISATION}${collectionSlug}`,
                            title: collectionName,
                            disabled: false,
                            isStatic: false
                        };
                        links.value.splice(1, 0, orgListItem);
                    }
                });
            }
        }, { immediate: true });
        return {
            links
        };
    }
});
