const NotFound = () => import('@/status-pages/404.vue');
const ServerError = () => import('@/status-pages/500.vue');
const ComingSoon = () => import('@/status-pages/ComingSoon.vue');
const HallOfFame = () => import('@/static-pages/HallOfFame.vue');
const AboutUs = () => import('@/static-pages/AboutUs.vue');
const SecurityPolicy = () => import('@/static-pages/SecurityPolicy.vue');
const VenueSuccessPage = () => import(
/* webpackChunkName: "venue_success.route" */ '@/pages/vendor/venue/VenueSuccessPage.vue');
const SpaceSuccessPage = () => import(
/* webpackChunkName: "space_success.route" */ '@/pages/vendor/space/SpaceSuccessPage.vue');
export const rootRoutes = [
    {
        path: '/not-found',
        localePath: 'not-found',
        name: 'not-found',
        component: NotFound
    },
    {
        path: '/coming-soon',
        localePath: 'coming-soon',
        name: 'coming-soon',
        component: ComingSoon
    },
    {
        path: '/hall-of-fame',
        localePath: 'hall-of-fame',
        name: 'hall-of-fame',
        component: HallOfFame
    },
    {
        path: '/about-us',
        localePath: 'about-us',
        name: 'about-us',
        component: AboutUs
    },
    {
        path: '/company/about-us', // Redirects from the old routes, needed for SEO
        localePath: 'company/about-us',
        redirect: 'about-us'
    },
    {
        path: '/security-policy',
        localePath: 'security-policy',
        name: 'security-policy',
        component: SecurityPolicy
    },
    {
        path: '/server-error',
        localePath: 'server-error',
        component: ServerError
    },
    {
        path: '/venue-success',
        localePath: 'venue-success',
        name: 'venue-success',
        component: VenueSuccessPage
    },
    {
        path: '/space-success',
        localePath: 'space-success',
        component: SpaceSuccessPage
    }
];
