import { render, staticRenderFns } from "./HomeHowTo.vue?vue&type=template&id=ab1326a8&scoped=true"
import script from "./HomeHowTo.vue?vue&type=script&lang=ts"
export * from "./HomeHowTo.vue?vue&type=script&lang=ts"
import style0 from "./HomeHowTo.vue?vue&type=style&index=0&id=ab1326a8&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ab1326a8",
  null
  
)

export default component.exports