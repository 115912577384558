import { watch, computed, defineComponent, ref } from '@vue/composition-api';
import Toggle, { Option } from '@/uikit/Toggle.vue';
import { TestimonialView } from '@/Home/home.const';
import { useGetAllFeedbacksQuery, UserRoleState } from '@/generated-types/graphql.types';
export default defineComponent({
    name: 'Testimonials',
    components: {
        Option,
        Toggle
    },
    setup(_, context) {
        const { root } = context;
        const allFeedbacks = ref([]);
        const { onResult } = useGetAllFeedbacksQuery();
        onResult((result) => {
            allFeedbacks.value = result?.data?.get_all_feedbacks || [];
        });
        const toggleViewMode = ref(TestimonialView.BOOKER);
        const bookerFeedbacks = computed(() => {
            return allFeedbacks.value.filter(feedback => feedback.user_role === UserRoleState.Booker);
        });
        const vendorFeedbacks = computed(() => {
            return allFeedbacks.value
                .filter(feedback => feedback.user_role === UserRoleState.Vendor)
                .filter(Boolean);
        });
        /**
         * Watch booker feedbacks. If none, switch view mode to vendors.
         */
        watch(() => bookerFeedbacks.value, () => {
            if (!bookerFeedbacks.value.length) {
                toggleViewMode.value = TestimonialView.VENDOR;
            }
        }, { immediate: true });
        /**
         * Watch vendor feedbacks. If none, switch view mode to bookers.
         */
        watch(() => vendorFeedbacks.value, () => {
            if (!vendorFeedbacks.value.length) {
                toggleViewMode.value = TestimonialView.BOOKER;
            }
        }, { immediate: true });
        const switchToggleViewMode = () => {
            toggleViewMode.value =
                toggleViewMode.value === TestimonialView.BOOKER
                    ? TestimonialView.VENDOR
                    : TestimonialView.BOOKER;
            onSlideChange();
        };
        const slidesBreakpoints = {
            1200: {
                fixedHeight: '400px'
            },
            960: {
                fixedHeight: '500px',
                touchable: true
            },
            375: {
                fixedHeight: '500px',
                touchable: true
            }
        };
        const sliderVisible = ref(false);
        /**
         * Callback from the v-observe-visibility directive.
         * @param isVisible
         * @param _
         */
        const sliderVisibilityChanged = (isVisible, _) => {
            sliderVisible.value = isVisible;
        };
        const chunkSize = ref(100);
        watch(() => root.$mq, () => {
            if (root.$mq.phone) {
                chunkSize.value = 30;
            }
            else if (root.$mq.tablet) {
                chunkSize.value = 50;
            }
            else {
                chunkSize.value = 100;
            }
        }, { immediate: true, deep: true });
        /**
         * Split feedback text into chunks of a given size.
         * @param text
         */
        const chunkText = (text) => {
            const result = [];
            const words = text.split(' ');
            let currentChunk = '';
            words.forEach((word) => {
                if (currentChunk.length + word.length > chunkSize.value) {
                    result.push(currentChunk);
                    currentChunk = word;
                }
                else {
                    currentChunk += (currentChunk.length > 0 ? ' ' : '') + word;
                }
            });
            if (currentChunk.length > 0) {
                result.push(currentChunk);
            }
            return result;
        };
        const animationKey = ref(0);
        /**
         * Callback from the vueper-slides component that updates the animation key to restart the animation.
         */
        const onSlideChange = () => {
            animationKey.value += 1;
        };
        return {
            allFeedbacks,
            animationKey,
            bookerFeedbacks,
            chunkSize,
            scopedFeedbacks: computed(() => {
                return toggleViewMode.value === TestimonialView.BOOKER
                    ? bookerFeedbacks.value
                    : vendorFeedbacks.value;
            }),
            sliderVisible,
            slidesBreakpoints,
            toggleViewMode,
            vendorFeedbacks,
            TestimonialView,
            chunkText,
            onSlideChange,
            sliderVisibilityChanged,
            switchToggleViewMode
        };
    }
});
