import { render, staticRenderFns } from "./Checkbox.vue?vue&type=template&id=bd64add0&scoped=true"
import script from "./Checkbox.vue?vue&type=script&lang=ts"
export * from "./Checkbox.vue?vue&type=script&lang=ts"
import style0 from "./Checkbox.vue?vue&type=style&index=0&id=bd64add0&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bd64add0",
  null
  
)

export default component.exports