import { GeoPagesRoutes } from '@/GeoPages/geopages.const';
import GeoHeader from '@/GeoPages/components/GeoHeader.vue';
import SearchHeader from '@/Search/components/SearchHeader.vue';
/** Components */
const EmptyLayout = () => import(/* webpackChunkName: "locations.route" */ '@/layouts/EmptyLayout.vue');
const EntitiesList = () => import(
/* webpackChunkName: "locations.route" */ '@/GeoPages/pages/EntitiesList.vue');
const City = () => import(/* webpackChunkName: "city.route" */ '@/GeoPages/pages/City.vue');
const Region = () => import(/* webpackChunkName: "region.route" */ '@/GeoPages/pages/Region.vue');
export const geoPagesRoutes = [
    {
        path: `/${GeoPagesRoutes.CITIES}`,
        localePath: GeoPagesRoutes.CITIES,
        meta: {
            breadcrumbs: GeoHeader,
            header: SearchHeader
        },
        component: EmptyLayout,
        children: [
            {
                path: '/',
                component: EntitiesList
            },
            {
                path: GeoPagesRoutes.REGIONS,
                component: Region
            },
            {
                name: 'city',
                path: GeoPagesRoutes.CITY,
                component: City,
                children: [
                    // this will render the City for these kind of URLs
                    // - /locations/noord-holland/amsterdam/training%20room
                    // - /en/locations/zuid-holland/rotterdam/training%20room
                    {
                        name: 'city_by_tag',
                        path: ':tag',
                        component: City
                    }
                ]
            }
        ]
    },
    // matches only the invalid city page URLs like https://wemeet.nl/utrecht/utrecht
    // and redirects to the correct URL https://wemeet.nl/locations/utrecht/utrecht
    {
        path: '/:region(noord-holland|noord-brabant|overijssel|utrecht|zuid-holland|friesland|gelderland|flevoland|groningen)/:city', // Redirects from the invalid routes, needed for SEO
        localePath: ':region(noord-holland|noord-brabant|overijssel|utrecht|zuid-holland|friesland|gelderland|flevoland|groningen)/:city',
        redirect: to => {
            return `/${GeoPagesRoutes.CITIES}/${to.params.region}/${to.params.city}`;
        }
    },
    {
        path: '/:region(friesland|flevoland)', // Redirects from the invalid routes, needed for SEO
        localePath: ':region(friesland|flevoland)',
        redirect: to => {
            return `/${GeoPagesRoutes.CITIES}/${to.params.region}`;
        }
    }
];
